import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private auth: AuthService, private router: Router) { }


  checkAuthentication(path: String): boolean {
    this.auth.getUser();
    const loggedIn = this.auth.check();
    if (!loggedIn) {
      this.auth.handleLogin(`${path}`);
    } else {
      this.auth.getUserServer();
    }
    return loggedIn;
  }

  canLoad(route: Route): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkAuthentication(route.path);
  }

  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuthentication(state.url);
  }

  canActivateChild(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.auth.getUser();
    const loggedIn = this.auth.check();
    if (!loggedIn) {
      this.auth.handleLogin();
    }
    return loggedIn;
  }
}
