<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="'navigation'" [mode]="'side'"
    [opened]="(isHandset$ | async) === false">
    <mat-toolbar color="primary" class="j-c-center">
      <img src="./assets/img/logo_white.svg" alt="" srcset="">
    </mat-toolbar>
    <mat-nav-list class="">
      <a mat-list-item href="#">
        <mat-icon svgIcon="dashboard"></mat-icon>
        <span>Visão geral</span>
      </a>
      <!-- <a mat-list-item href="#">
        <mat-icon svgIcon="user"></mat-icon>
        <span>Dados pessoais</span>
      </a>
      <a mat-list-item href="#">
        <mat-icon svgIcon="cards"></mat-icon>
        <span>Minha assinatura</span>
      </a> -->
      <a mat-list-item [routerLink]="[ '/courses' ]">
        <mat-icon svgIcon="books"></mat-icon>
        <span>Meus cursos</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="example-spacer"></span>

      <div class="user-wrapper" [matMenuTriggerFor]="menu">
        <div class="user-avatar">
          <img [src]="user?.image" alt="" srcset="">
        </div>
        <div class="user-name">{{user?.name}}</div>
      </div>
      <mat-menu #menu="matMenu" xPosition="before">
        <!-- <button mat-menu-item>Item 1</button> -->
        <div mat-menu-item (click)="$event.stopPropagation()">
          Tema: <mat-slide-toggle [(ngModel)]="isDarkTheme" (change)="setTheme()"> &nbsp;&nbsp;Escuro
          </mat-slide-toggle>
        </div>
        <button mat-menu-item (click)="authSvc.logout()">Sair</button>
      </mat-menu>
    </mat-toolbar>
    <!-- Add Content Here -->
    <div class="content-wrapper">
      <ng-content></ng-content>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
