import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';



@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  isDarkTheme: boolean = false;
  initTheme(){
    this.isDarkTheme = false;
    this.changeTheme();
  }
  changeTheme() {
    let bodyElement: HTMLElement = document.querySelector('body');
    localStorage.setItem('dark_theme', this.isDarkTheme.toString());
    if (this.isDarkTheme) {
      bodyElement.classList.add('dark-theme');
      this.setTheme({
        background_color: '#0b0d17',
        background_color_contrast: '#fbfbfb',
        background_color_highlight: '#1f222f',
        background_color_highlight_contrast: '#fbfbfb',
        primary_color_50: '#f1e1e0',
        primary_color_100: '#ddb3b3',
        primary_color_200: '#c68080',
        primary_color_300: '#af4d4d',
        primary_color_400: '#9e2726',
        primary_color_500: '#8d0100',
        primary_color_600: '#850100',
        primary_color_700: '#7a0100',
        primary_color_800: '#700100',
        primary_color_900: '#5d0000',
        primary_color_A100: '#ff8e8e',
        primary_color_A200: '#ff5b5b',
        primary_color_A400: '#ff2828',
        primary_color_A700: '#ff0e0e',
        primary_color_50_contrast: '#000000',
        primary_color_100_contrast: '#000000',
        primary_color_200_contrast: '#000000',
        primary_color_300_contrast: '#ffffff',
        primary_color_400_contrast: '#ffffff',
        primary_color_500_contrast: '#ffffff',
        primary_color_600_contrast: '#ffffff',
        primary_color_700_contrast: '#ffffff',
        primary_color_800_contrast: '#ffffff',
        primary_color_900_contrast: '#ffffff',
        primary_color_A100_contrast: '#000000',
        primary_color_A200_contrast: '#000000',
        primary_color_A400_contrast: '#ffffff',
        primary_color_A700_contrast: '#ffffff'
      })
    } else {
      bodyElement.classList.remove('dark-theme');
      this.setTheme({
        background_color: '#D5D5D5',
        background_color_contrast: '#1f222f',
        background_color_highlight: '#ffffff',
        background_color_highlight_contrast: '#1f222f',
        primary_color_50: '#f1e1e0',
        primary_color_100: '#ddb3b3',
        primary_color_200: '#c68080',
        primary_color_300: '#af4d4d',
        primary_color_400: '#9e2726',
        primary_color_500: '#8d0100',
        primary_color_600: '#850100',
        primary_color_700: '#7a0100',
        primary_color_800: '#700100',
        primary_color_900: '#5d0000',
        primary_color_A100: '#ff8e8e',
        primary_color_A200: '#ff5b5b',
        primary_color_A400: '#ff2828',
        primary_color_A700: '#ff0e0e',
        primary_color_50_contrast: '#000000',
        primary_color_100_contrast: '#000000',
        primary_color_200_contrast: '#000000',
        primary_color_300_contrast: '#ffffff',
        primary_color_400_contrast: '#ffffff',
        primary_color_500_contrast: '#ffffff',
        primary_color_600_contrast: '#ffffff',
        primary_color_700_contrast: '#ffffff',
        primary_color_800_contrast: '#ffffff',
        primary_color_900_contrast: '#ffffff',
        primary_color_A100_contrast: '#000000',
        primary_color_A200_contrast: '#000000',
        primary_color_A400_contrast: '#ffffff',
        primary_color_A700_contrast: '#ffffff'
      })
    }
  }
  var_css = [
    "primary_color_1",
    "primary_color_2",
    "background_color",
    "background_color_contrast"
  ];
  constructor(
    private titleService: Title
  ) { }


  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public initApp(config) {
    this.setFavicon(config.url_favicon);
    // let color = this.computeColor("#96d600")
    this.setTheme(config)


  }

  public setCssVariables(prefix, color) {

    if (Array.isArray(color)) {
      color.forEach(
        (element) => {
          document.querySelector('html').style.setProperty('--' + prefix + "_" + element.name, element.hex);
          document.querySelector('html').style.setProperty('--' + prefix + "_" + element.name + "_contrast", ((element.darkContrast) ? 'var(--color_font_default_dark)' : 'var(--color_font_default_light)'));
        });
    }

  }

  // public computeColor(hex) {
  //   let algoritimo = 'constantin';
  //   if (algoritimo == 'constantin') {
  //     const baseLight = tinycolor('#ffffff');
  //     const baseDark = this.multiply(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
  //     const baseTriad = tinycolor(hex).tetrad();
  //     return [
  //       this.getColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
  //       this.getColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
  //       this.getColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
  //       this.getColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
  //       this.getColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
  //       this.getColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
  //       this.getColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
  //       this.getColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
  //       this.getColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
  //       this.getColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
  //       this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(65), 'A100'),
  //       this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(55), 'A200'),
  //       this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(45), 'A400'),
  //       this.getColorObject(tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(40), 'A700')
  //     ];
  //   } else {
  //     return [
  //       this.getColorObject(tinycolor(hex).lighten(52), '50'),
  //       this.getColorObject(tinycolor(hex).lighten(37), '100'),
  //       this.getColorObject(tinycolor(hex).lighten(26), '200'),
  //       this.getColorObject(tinycolor(hex).lighten(12), '300'),
  //       this.getColorObject(tinycolor(hex).lighten(6), '400'),
  //       this.getColorObject(tinycolor(hex), '500'),
  //       this.getColorObject(tinycolor(hex).darken(6), '600'),
  //       this.getColorObject(tinycolor(hex).darken(12), '700'),
  //       this.getColorObject(tinycolor(hex).darken(18), '800'),
  //       this.getColorObject(tinycolor(hex).darken(24), '900'),
  //       this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
  //       this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
  //       this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
  //       this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
  //     ];
  //   }
  // }

  public setFavicon(link) {
    let $favicon: any = document.querySelector('link[rel="icon"]')
    if ($favicon !== null) {
      $favicon.href = link
    } else {
      $favicon = document.createElement("link")
      $favicon.rel = "icon"
      $favicon.href = link
      document.head.appendChild($favicon)
    }
  }



  // public multiply(rgb1, rgb2) {
  //   rgb1.b = Math.floor(rgb1.b * rgb2.b / 255);
  //   rgb1.g = Math.floor(rgb1.g * rgb2.g / 255);
  //   rgb1.r = Math.floor(rgb1.r * rgb2.r / 255);
  //   return tinycolor('rgb ' + rgb1.r + ' ' + rgb1.g + ' ' + rgb1.b);
  // };

  // public getColorObject(value, name) {
  //   var c = tinycolor(value);
  //   return {
  //     name: name,
  //     hex: c.toHexString(),
  //     darkContrast: c.isLight()
  //   };
  // }

  public setTheme(stylesheet) {
    for (const key in stylesheet) {
      if (stylesheet.hasOwnProperty(key)) {
        const element = stylesheet[key];
        if (key == 'url_favicon') {
          this.setFavicon(element);
        }
        if (key.indexOf('url') >= 0) {
          document.querySelector('html').style.setProperty('--' + key, 'url("' + element + '")');
        } else {
          // if (this.var_css.includes(key)) {
            if (key.indexOf('color') >= 0) {
            document.querySelector('html').style.setProperty('--' + key, element);
            // this.setCssVariables(key, element);
          } else {
            if (key.indexOf('font_size') >= 0) {
              document.querySelector('html').style.setProperty('--' + key, element + 'px');
            } else {
              document.querySelector('html').style.setProperty('--' + key, element);
            }

          }
        }
      }
    }
  }

  public rgbToHex(rgbArray) {
    return '#' + rgbArray.map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }).join('')
  }

  public GetMaterialColors(color: string) {
    let result = [];
    result[0] = this.shadeColor(color, 0.9); //----> 50
    result[1] = this.shadeColor(color, 0.7); //----> 100
    result[2] = this.shadeColor(color, 0.5); //----> 200
    result[3] = this.shadeColor(color, 0.333); //----> 300
    result[4] = this.shadeColor(color, 0.166); //----> 400
    result[5] = this.shadeColor(color, 0); //----> 500
    result[6] = this.shadeColor(color, -0.125); //----> 600
    result[7] = this.shadeColor(color, -0.25); //----> 700
    result[8] = this.shadeColor(color, -0.375); //----> 800
    result[9] = this.shadeColor(color, -0.5); //----> 900
    result[10] = this.shadeColor(color, 0.7); //----> A100
    result[11] = this.shadeColor(color, 0.5); //----> A200
    result[12] = this.shadeColor(color, 0.166); //----> A400
    result[13] = this.shadeColor(color, -0.25); //----> A700

    return result;
  }

  private shadeColor(color: string, percent: number) {
    let f = Number.parseInt(color.substring(1), 16);
    let t = percent < 0 ? 0 : 255;
    let p = percent < 0 ? percent * -1 : percent;
    let R = f >> 16;
    let G = f >> 8 & 0x00FF;
    let B = f & 0x0000FF;
    let red = (Math.round((t - R) * p) + R);
    let green = (Math.round((t - G) * p) + G);
    let blue = (Math.round((t - B) * p) + B);
    return this.rgbToHex([red, green, blue]);
  }



}

