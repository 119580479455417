import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from './core/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'edon-ead';
  icons = [
    'dashboard',
    'user',
    'cards',
    'books'
  ]

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public themeSvc: ThemeService
    ) {
    this.icons.forEach(icon => iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(`assets/img/${icon}.svg`)))
    this.themeSvc.initTheme()
  }
}
