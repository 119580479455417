import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { User } from '../interfaces/user.model';
import { environment } from './../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  lastUrl: string;
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.router.events.pipe(filter(e => e instanceof NavigationEnd)
    ).subscribe((e: NavigationEnd) => this.lastUrl = e.url);

  }

  check(): boolean {
    return localStorage.getItem('user') ? true : false;
  }

  login(credentials: { email: string, password: string }): Observable<boolean> {
    return this.http.post<any>(`${environment.api_url}/authenticate`, credentials)
      .pipe(
        tap(data => {
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token);
          localStorage.setItem('token_type', data.token_type);
          localStorage.setItem('user', btoa(JSON.stringify(data.user)));
        })
      )

  }

  logout(): void {
    this.http.get(`${environment.api_url}/authenticate/logout`).subscribe();
    localStorage.clear();
    this.router.navigate(['/auth/login'], { replaceUrl: true });
  }

  getUser(): User {
    try {
      return localStorage.getItem('user') ? JSON.parse(atob(localStorage.getItem('user'))) : null;
    } catch (e) {
      this.logout();
    }
  }


  getUserServer() {
    return this.http.get<any>(`${environment.api_url}/authenticate`)
    .pipe(tap(data => {
      localStorage.setItem('user', btoa(JSON.stringify(data.user)));
    }))
  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['/auth/login', btoa(path)]);
  }

  setUser(): Promise<boolean> {
    return this.http.get<any>(`${environment.api_url}/authenticate`).toPromise()
      .then(data => {
        if (data.user) {
          localStorage.setItem('user', btoa(JSON.stringify(data.user)));
          return true;
        }
        return false;
      });
  }

  resetPassword(data: any) {
    return this.http.post<any>(`${environment.api_url}/password/email`, data);
  }

  setNewPassword(data: any) {
    return this.http.post<any>(`${environment.api_url}/password/reset`, data);
  }

}
