import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from './../../core/interfaces/user.model';
import { CourseUser } from './../interfaces/course-user.model';

@Injectable()
export class UsersService {

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<User[]> {
    return this.http.get<any>(`${environment.api_url}/users`);
  }

  getCourses(): Observable<any> {
    return this.http.get<CourseUser[]>(`${environment.api_url}/courses-users`);
  }

  checkCourseUserOrCreate(data: any): Observable<any> {
    return this.http.post<CourseUser[]>(`${environment.api_url}/courses-users/check-or-create`, data);
  }

  getCourse(id: number): Observable<any> {
    return this.http.get<any>(`${environment.api_url}/courses-users/${id}`);
  }

  create(user: User): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/users`, user);
    // .catch(error => AplicationErrorHandle.handleError(error));
  }

  syncProfilePicture(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/users/attachmentIndexing`, data);
  }

  removeProfilePicture(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/users/attachmentDetach`, data);
  }

  setPassword(data: any): Observable<any> {
    return this.http.post<any>(`${environment.api_url}/users/setPassword`, data)
      // .catch(error => AplicationErrorHandle.handleError(error));
  }
  update(id: number, user: any): Observable<any> {
    return this.http.put<any>(`${environment.api_url}/users/${id}`, user);
  }

  get(id: number): Observable<User> {
    return this.http.get<any>(`${environment.api_url}/users/${id}`);
  }

  remove(id: number): Observable<User> {
    return this.http.delete<any>(`${environment.api_url}/users/${id}`);
  }

}
