import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ThemeService } from 'src/app/core/services/theme.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/interfaces/user.model';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenubarComponent implements OnInit {
  isDarkTheme: boolean = false;
  user: User;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public themeSvc: ThemeService,
    public authSvc: AuthService
  ) { }

  async ngOnInit() {
    let isDarkTheme = localStorage.getItem('dark_theme');
    this.isDarkTheme = (isDarkTheme && isDarkTheme != 'false') ? true : false;
    this.user =  this.authSvc.getUser()

    // let userResponse = await this.authSvc.getUserServer().toPromise()
  }

  setTheme(){
    this.themeSvc.isDarkTheme = this.isDarkTheme;
    this.themeSvc.changeTheme()
  }



}
