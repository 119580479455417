export const environment = {
  production: true,
  url_annotation: 'https://'+window.location.hostname+'/lesson-annotation',
  api_url: 'https://api.edon.com.br/public/api/v1',
  url_senderEmail: 'https://loja.edon.com.br/send/envia.php',
  url_senderEmailNews: 'https://loja.edon.com.br/send/newsletter-subscribe.php',
  url_upload: 'https://api.edon.com.br/public/api/v1/attachments/image',
  url_pagseguro: 'https://ws.pagseguro.uol.com.br/v2',
  api_articles: 'https://blog.edon.com.br',
  email_pagseguro: 'desenvolvimento@agenciar8.com.br',
  token_pagseguro: 'D1803A0D67FE429BBD14128E525CC54C'
};
